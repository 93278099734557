import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AlertService } from '../alert/alert.service';
import { SnackbarService } from 'ngx-snackbar';
import { merge } from 'ramda';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  constructor(
    private readonly http: HttpClient,
    private readonly alertService: AlertService,
    private readonly snackBarService: SnackbarService,
    private readonly router: Router
  ) {}
  isShowingError: boolean = false;

  BASE_AUTH_HEADERS = {
    'Content-Type': 'application/json',
  };

  BASE_REQUEST_OPTIONS = {
    headers: new HttpHeaders(this.BASE_AUTH_HEADERS),
  };

  public get(url: string, options?: any): Observable<any> {
    return this.http.get(url, this.mergeRequestOptionsWithBaseOptions(options));
  }

  public post(url: string, data: any, options?: any): Observable<any> {
    return this.http.post(
      url,
      JSON.stringify(data),
      this.mergeRequestOptionsWithBaseOptions(options)
    );
  }

  public put(url: string, data: any, options?: any) {
    return this.http.put(url, data, options);
  }

  public delete(url: string, options?: any) {
    return this.http.delete(url, options);
  }

  public handleError(err, softError = false, msg = null, redirectUrl = null) {
    if (this.isShowingError) return;

    let errMessage = msg;
    if (err.error?.errors?.length > 0 && msg === null) {
      errMessage = err.error?.errors[0];
    }
    if (softError) {
      this.snackBarService.add({
        msg: errMessage,
      });
    } else {
      this.isShowingError = true;
      this.alertService.newInfoAlert('Erro!', errMessage).then(
        () => {
          this.isShowingError = false;
          if (redirectUrl) {
            this.router.navigate([redirectUrl]);
          }
        },
        () => {
          this.isShowingError = false;
          if (redirectUrl) {
            this.router.navigate([redirectUrl]);
          }
        }
      );
    }
  }

  private mergeRequestOptionsWithBaseOptions(requestOptions: any): object {
    return merge(this.BASE_REQUEST_OPTIONS, requestOptions);
  }
}
