import { Injectable } from '@angular/core';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private infoStyle = `
  <style>
  .swal2-modal {
    box-shadow: 0px 5px 10px #2e2e301a;
    border-radius: 20px;
    padding: 2rem;
    max-width: 460px;
  }
  .swal2-popup {
    width: 42em;
  }
  .swal2-header {
    padding: 0;
  }
  .swal2-title {
    margin-right: auto !important;
    font-family: Asap-Bold;
    font-size: 1.8rem;
    color: var(--text-color);
  }
  .swal2-content {
    text-align: left;
    padding: 1.5rem 0;
    white-space: pre-wrap;
  }
  #swal2-content {
    font-family: Montserrat-Regular;
    font-size: 1rem;
    display: flex !important;
    flex-direction: column;
  }
  .swal2-styled.swal2-confirm {
    width: 100%;
    height: 4rem;
    box-shadow: 0px 5px 10px #2E2E3033;
    border-radius: 8px;
    background-color: var(--orange-color);
    flex: 1;
  }
  .swal2-styled.swal2-cancel {
    height: 4rem;
    box-shadow: 0px 5px 10px #2E2E3033;
    border-radius: 8px;
  }
  .swal2-close {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    color: var(--text-color);
    visibility: hidden;
  }
  .swal2-close:after {
    content: "✕";
    visibility: visible;
    display: block;
    position: absolute;
    color: var(--text-color);
    top: 1.8rem;
    right: 1.8rem;
    font-size: 1.4rem;
  }
  .small-text {
    font-size: 12px;
    margin-top: 6px;
  }
  </style>
  `;

  public constructor(private readonly swalTargets: SweetAlert2LoaderService) {
  }

  public async newInfoAlert(title, content): Promise<void> {
    (await this.swalTargets.swal).fire({
      title: title,
      html: this.infoStyle + content,
      showCloseButton: true
    });
  }

  public async newErrorAlert(content): Promise<void> {
    (await this.swalTargets.swal).fire({
      title: 'Erro!',
      html: this.infoStyle + content,
      showCloseButton: true
    });
  }

  public async newConfirmAlert(content, onConfirm, title = 'Cuidado!', confirmLabel = 'Excluir', cancelLabel = 'Cancelar'): Promise<void> {
    let description = null;

    (await this.swalTargets.swal)
      .fire({
        title: title,
        html: this.infoStyle + content,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: confirmLabel,
        cancelButtonText: cancelLabel,
        input: 'text',
        inputPlaceholder: 'Motivo',
        inputValue: '',
        inputValidator: (value) => {
          if (!value) {
            return '';
          }

          description = value;
        },

        preConfirm: () => {
          if (onConfirm) onConfirm(description);
        }
      })
      .finally(() => {
      });
  }

  public async newConfirmationModal(content, onConfirm, onCancel, title, confirmLabel = 'Sim', cancelLabel = 'Não'): Promise<void> {

    (await this.swalTargets.swal)
      .fire({
        title: title,
        html: this.infoStyle + content,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: confirmLabel,
        cancelButtonText: cancelLabel
      }).then((result) => {
      if (result.isConfirmed) {
        if (onConfirm) onConfirm();
      }
      if (result.isDismissed) {
        if (onCancel) onCancel();
      }
    });
  }
}
