import { Injectable } from '@angular/core';
import { ApiHttpService } from './api-http.service';
import { Constants } from 'src/app/config/constants';
import { PostalCode } from 'src/app/shared/models/postal-code';
import { Address } from 'src/app/shared/models/address';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewOrderApiService {
  constructor(private apiService: ApiHttpService) { }

  public getAddressCep(cep: string, servicedArea: boolean = false): Promise<PostalCode> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(
          `${Constants.API_ADDRESS_CEP}?postalCode=${cep?.replace(/\D/g, '')}&servicedArea=${servicedArea}`
        )
        .subscribe(
          (res) => {
            resolve(res?.data);
          },
          (err) => {
            this.apiService.handleError(err, false);
            reject(err);
          }
        );
    });
  }

  public getDistancePrice(
    origin: Address,
    destination: Address,
    truckTypeId: string,
    totalItems: any,
    errCallBack
  ): Promise<{
    distance: string;
    duration: string;
    value: number;
  }> {
    let originDesc = `${origin.street} ${origin.number}, ${origin.neighborhood} ${origin.city} ${origin.uf}`;
    let destinationDesc = `${destination.street} ${destination.number}, ${destination.neighborhood} ${destination.city} ${destination.uf}`;
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(
          `${Constants.API_CALCULATE_ROUTE}?origin=${originDesc}&destiny=${destinationDesc}&typeTruckId=${truckTypeId}&quantityItem=${totalItems}`
        )
        .subscribe(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            this.apiService.handleError(err);
            if (errCallBack)
              errCallBack();
            reject(err);
          }
        );
    });
  }

  public getMovingType(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`${Constants.API_MOVING_TYPES}`).subscribe(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          this.apiService.handleError(
            err,
            false,
            'Falha ao carregar os dados, tente novamente mais tarde.'
          );
          reject(err);
        }
      );
    });
  }

  public getExtraHelpInformation(): Promise<
    {
      helpersId: string;
      name: string;
      value: number;
      quantity: number;
    }[]
  > {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`${Constants.API_EXTRA_HELP_INFO}`).subscribe(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          this.apiService.handleError(
            err,
            false,
            'Falha ao carregar os dados, tente novamente mais tarde.'
          );
          reject(err);
        }
      );
    });
  }

  public getPackingMaterial(): Promise<
    {
      taxDelivery: number,
      packagingMaterial: {
        packagingMaterialId: string;
        name: string;
        icon: string;
        value: number;
      }[]
    }
  > {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`${Constants.API_PACKING_MATERIAL}`).subscribe(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          this.apiService.handleError(
            err,
            false,
            'Falha ao carregar os dados, tente novamente mais tarde.'
          );
          reject(err);
        }
      );
    });
  }

  public getVehicleTypeOptions(): Promise<{
    name: string;
    truckTypeId: string;
    photo1: string;
    photo2: string;
    photo3: string;
  }[]> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`${Constants.API_VEHICLE_TYPE}`).subscribe(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          this.apiService.handleError(
            err,
            false,
            'Falha ao carregar os dados, tente novamente mais tarde.'
          );
          reject(err);
        }
      );
    });
  }

  public getCreditCardBrans(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`${Constants.API_GET_CARD_BRANS}`).subscribe(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          this.apiService.handleError(
            err,
            false,
            'Falha ao carregar os dados, tente novamente mais tarde.'
          );
          reject(err);
        }
      );
    });
  }

  public getInsuranceInfo(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`${Constants.API_GET_INSURANCE_INFO}`).subscribe(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          this.apiService.handleError(
            err,
            false,
            'Falha ao carregar os dados, tente novamente mais tarde.'
          );
          reject(err);
        }
      );
    });
  }

  public postNewOrder(data): Observable<any> {
    return this.apiService.post(`${Constants.API_NEW_ORDER}`, data).pipe(
      map(({ data: responseData }) => responseData),
      catchError((error) => {
        this.apiService.handleError(error);

        return throwError(error);
      })
    );
  }
  public postStepLog(data): Observable<any> {
    return this.apiService.post(`${Constants.API_NEW_ORDER_STEP_LOG}`, data).pipe(
      map(({ data: responseData }) => responseData),
      catchError((error) => {
        this.apiService.handleError(error);

        return throwError(error);
      })
    );
  }

  public getInsuranceValue(value: number): Promise<number> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(`${Constants.API_CALCULATE_INSURANCE}?value=${value}`)
        .subscribe(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            this.apiService.handleError(err);
            reject(err);
          }
        );
    });
  }

  getInstallments(
    paymentValue: number,
    creditCardBrand: string,
    couponCode: string,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(`${Constants.API_CALCULATE_INSTALLMENTS}?value=${paymentValue}&brand=${creditCardBrand}&code=${encodeURIComponent(couponCode)}`)
        .subscribe(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            this.apiService.handleError(err);
            reject(err);
          }
        );
    });
  }

  getIsValidCoupon(coupon: string, value?: any ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(`${Constants.API_GET_VALID_COUPON}?code=${coupon}&value=${value}`)
        .subscribe(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            this.apiService.handleError(err);
            reject(err);
          }
        );
    });
  }
}
