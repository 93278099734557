import { Injectable } from '@angular/core';

const getApiUrl = () => {
  switch (window.location.host) {
    case 'stag-portal.graneroexpress.com.br': // Stag
      return 'https://api-stag.graneroexpress.com.br';
    case 'portal.graneroexpress.com.br': // Prod
      return 'https://api.graneroexpress.com.br';

    default:
      return 'https://api-dev.graneroexpress.com.br';
  }
};

@Injectable()
export class Constants {
  public static readonly API_ENDPOINT: string = getApiUrl();
  public static readonly API_LOGIN: string =
    Constants.API_ENDPOINT + '/authentication/v1/login';
  public static readonly API_CHANGE_PASS: string =
    Constants.API_ENDPOINT + '/authentication/v2/change-password-portal';
  public static readonly API_CHECK_TOKEN: string =
    Constants.API_ENDPOINT + '/authentication/v2/token-change-password-portal';
  public static readonly API_CREATE_ACCOUNT: string =
    Constants.API_ENDPOINT + '/authentication/v2/create-portal';
  public static readonly API_CREATE_BY_GOOGLE: string =
    Constants.API_ENDPOINT + '/authentication/v1/create-by-google';
  public static readonly API_CREATE_BY_FACEBOOK: string =
    Constants.API_ENDPOINT + '/authentication/v1/create-by-facebook';
  public static readonly API_LOGIN_BY_GOOGLE: string =
    Constants.API_ENDPOINT + '/authentication/v1/login-by-google';
  public static readonly API_LOGIN_BY_FACEBOOK: string =
    Constants.API_ENDPOINT + '/authentication/v1/login-by-facebook';
  public static readonly API_RECOVER_PASSWORD: string =
    Constants.API_ENDPOINT + '/authentication/v2/remember-password';
  public static readonly API_DASHBOARD: string =
    Constants.API_ENDPOINT + '/moving/v1';
  public static readonly API_ORDER_CANCEL: string =
    Constants.API_ENDPOINT + '/moving/v1/moving-canceled-by-client';
  public static readonly API_ADDRESS_CEP: string =
    Constants.API_ENDPOINT + '/postal-code/v1';
  public static readonly API_CALCULATE_ROUTE: string =
    Constants.API_ENDPOINT + '/google/v1/calculate-route';
  public static readonly API_EXTRA_HELP_INFO: string =
    Constants.API_ENDPOINT + '/helpers/v1';
  public static readonly API_MOVING_TYPES: string =
    Constants.API_ENDPOINT + '/moving/v1/type-moving';
  public static readonly API_PACKING_MATERIAL: string =
    Constants.API_ENDPOINT + '/packaging-material/v1';
  public static readonly API_VEHICLE_TYPE: string =
    Constants.API_ENDPOINT + '/truck-type/v1';
  public static readonly API_MY_PROFILE: string =
    Constants.API_ENDPOINT + '/authentication/v1/get-my-profile';
  public static readonly API_UPDATE_MY_PROFILE: string =
    Constants.API_ENDPOINT + '/authentication/v1/update-my-profile';
  public static readonly API_UPDATE_PASSWORD: string =
    Constants.API_ENDPOINT + '/authentication/v1/update-password';
  public static readonly API_NEW_ORDER: string =
    Constants.API_ENDPOINT + '/moving/v1';
  public static readonly API_NEW_ORDER_STEP_LOG: string =
    Constants.API_ENDPOINT + '/moving/v1/step';
  public static readonly API_CALCULATE_INSURANCE: string =
    Constants.API_ENDPOINT + '/moving-insurance/v1/calculate';
  public static readonly API_CALCULATE_INSTALLMENTS: string =
    Constants.API_ENDPOINT +
    '/brand-credit-card/v1/calculate-value-by-installments';
  public static readonly API_GET_CARD_BRANS: string =
    Constants.API_ENDPOINT + '/brand-credit-card/v1';
  public static readonly API_GET_RATING_FORM: string =
    Constants.API_ENDPOINT + '/moving/v1/rating-fill-form';
  public static readonly API_POST_RATING_MOVING: string =
    Constants.API_ENDPOINT + '/moving/v1/rating';
  public static readonly API_GET_TRACKING_MOVING: string =
    Constants.API_ENDPOINT + '/moving/v1/tracking';
  public static readonly API_GET_CANCELLATION_FINE: string =
    Constants.API_ENDPOINT + '/moving/v1/moving-canceled-by-client-fine-by-cancellation'
  public static readonly API_GET_PRICE_DETAILS: string =
    Constants.API_ENDPOINT + '/moving/v1/client/details-values'
  public static readonly API_GET_INSURANCE_INFO: string =
    Constants.API_ENDPOINT + '/parameter-insurance/v1/bo/parameters'
  public static readonly API_GET_VALID_COUPON: string =
    Constants.API_ENDPOINT + '/coupon-discount/v1/valid-coupon'
}
