import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AlertService } from './services/alert/alert.service';
import { ApiHttpService } from './services/http/api-http.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { SnackbarModule } from 'ngx-snackbar';
import { AuthenticationService } from './services/auth/authentication.service';
import { OrdersApiService } from './services/http/orders-api.service';
import { NewOrderApiService } from './services/http/new-order-api.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    NoopAnimationsModule,
    TooltipModule,
    SweetAlert2Module.forRoot(),
    BrowserModule,
    HttpClientModule,
    SnackbarModule.forRoot(),
    SocialLoginModule,
  ],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AlertService,
    AuthenticationService,
    ApiHttpService,
    OrdersApiService,
    NewOrderApiService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '843967607645-365ph1ugf9ivjko3tv00kofuqi4p0tf8.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('975273239994446'),
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
