import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { untilDestroyed } from '../../until-destroyed';
import { Title } from '@angular/platform-browser';
import { Logger } from 'src/app/services/logger-service/logger.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { APP_ROUTES } from 'src/app/app.routes';
import { CredentialsService } from 'src/app/services/auth/credentials.service';

let logger = new Logger('Base Layout');

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly authenticationService: AuthenticationService,
    private readonly credentialService: CredentialsService,
  ) {
    this.fullName = this.credentialService.credentials?.user.fullName?.replace(' ', '+') || (this.credentialService.credentials?.user as unknown as string)?.replace(' ', '+');
  }

  showHeader: boolean = false;
  hideProfile: boolean = false;
  fullName: string = '';

  ngOnInit(): void {
    /**
     * Intercept router changes and update the tab title
     */
    const onNavigationEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    onNavigationEnd
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )

      .subscribe((event) => {
        const title = `${event.title} - Granero`;
        if (title) {
          this.titleService.setTitle(title);
        }

        logger.debug(`${this.router.url} - ${event.title}`);

        this.showHeader = !(event.hideHeader === true);

        this.hideProfile = event.hideProfile;
        
        this.fullName = this.credentialService.credentials?.user.fullName.replace(' ', '+');
      });
  }

  ngOnDestroy() {}

  logOut(): void {
    this.authenticationService.logout().subscribe(() => {
      this.router.navigate([APP_ROUTES.LOGIN], { replaceUrl: true });
    });
  }

  goHome() {
    this.router.navigate([APP_ROUTES.ORDERS]);
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  get backgroundProfileImage() {
    return `url('https://ui-avatars.com/api?background=3c2ea6&color=fff&name=${this.fullName}')`;
  }
}
