import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { ApiHttpService } from "./api-http.service";
import { Constants } from "src/app/config/constants";

@Injectable({
  providedIn: "root",
})
export class OrdersApiService {
  constructor(private apiService: ApiHttpService) { }

  public getUserDashboard(
    page = 1,
    records = 5,
    filter = "Number",
    direction = "ASC"
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(
          `${Constants.API_DASHBOARD}?records=${records}&page=${page}&order=${filter}&direction=${direction}`
        )
        .subscribe(
          (res) => {
            resolve(res.data);
          },
          (err) => {
            this.apiService.handleError(err);
            reject(err);
          }
        );
    });
  }

  public cancelOrder(movingId: string, description: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .put(
          `${Constants.API_ORDER_CANCEL}`, {
          movingId: movingId,
          reasonCancellation: description
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.apiService.handleError(err);
            reject(err);
          }
        );
    });
  }

  public getCancellationFine(movingId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(
          `${Constants.API_GET_CANCELLATION_FINE}?movingId=${movingId}`)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.apiService.handleError(err);
            reject(err);
          }
        );
    });
  }

  public getPriceInformation(movingId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService
        .get(
          `${Constants.API_GET_PRICE_DETAILS}?movingId=${movingId}`)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            this.apiService.handleError(err);
            reject(err);
          }
        );
    });
  }
}
