import { Injectable } from '@angular/core';

import { Credentials, CredentialsService } from './credentials.service';
import { ApiHttpService } from '../http/api-http.service';
import { Constants } from 'src/app/config/constants';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';

export interface LoginContext {
  login: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private readonly credentialsService: CredentialsService,
    private readonly apiService: ApiHttpService
  ) { }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    return this.apiService
      .post(Constants.API_LOGIN, {
        login: context.login,
        password: context.password,
      })
      .pipe(
        map(({ data: responseData }) => {
          const credentials: Credentials = {
            user: responseData.applicationUser,
            accessToken: responseData.accessToken,
          };

          this.credentialsService.setCredentials(credentials, context.remember);

          return responseData;
        }),
        catchError((error: HttpErrorResponse) => {
          this.apiService.handleError(error);

          return throwError(error);
        })
      );
  }

  checkToken(token: string): Observable<any> {
    return this.apiService.get(`${Constants.API_CHECK_TOKEN}?token=${token}`).pipe(
        map(({ data: responseData }) => {
          return responseData;
        }),
        catchError((error: HttpErrorResponse) => {
          this.apiService.handleError(error);

          return throwError(error);
        })
      );
  }

  changePas(data: any): Observable<any> {
    return this.apiService
      .post(Constants.API_CHANGE_PASS, {
        password: data.password,
        confirmPassword: data.confirmPassword,
        tokenChangePassword: data.tokenChangePassword
      })
      .pipe(
        map(({ data: responseData }) => {
          const credentials: Credentials = {
            user: responseData.applicationUser,
            accessToken: responseData.accessToken,
          };

          this.credentialsService.setCredentials(credentials, true);

          return responseData;
        }),
        catchError((error: HttpErrorResponse) => {
          this.apiService.handleError(error);

          return throwError(error);
        }));
  }

  signUp(user: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    password: string;
  }): Observable<any> {
    return this.apiService.post(Constants.API_CREATE_ACCOUNT, user).pipe(
      map(({ data: responseData }) => {
        const credentials: Credentials = {
          user: responseData.applicationUser,
          accessToken: responseData.accessToken,
        };

        this.credentialsService.setCredentials(credentials, true);

        return responseData;
      }),
      catchError((error: HttpErrorResponse) => {
        this.apiService.handleError(error);

        return throwError(error);
      })
    );
  }

  loginByGoogle(token: string): Observable<any> {
    return this.apiService.post(Constants.API_LOGIN_BY_GOOGLE, {
      access_token: token,
    }).pipe(
      map(({ data: responseData }) => {
        const credentials: Credentials = {
          user: responseData.applicationUser,
          accessToken: responseData.accessToken,
        };

        this.credentialsService.setCredentials(credentials, true);

        return responseData;
      }),
      catchError((error: HttpErrorResponse) => {
        this.apiService.handleError(error);

        return throwError(error);
      })
    );
  }

  loginByFacebook(token: string): Observable<any> {
    return this.apiService.post(Constants.API_LOGIN_BY_FACEBOOK, {
      access_token: token,
    }).pipe(
      map(({ data: responseData }) => {
        const credentials: Credentials = {
          user: responseData.applicationUser,
          accessToken: responseData.accessToken,
        };

        this.credentialsService.setCredentials(credentials, true);

        return responseData;
      }),
      catchError((error: HttpErrorResponse) => {
        this.apiService.handleError(error);

        return throwError(error);
      })
    );
  }

  signUpByGoogle(token: string): Observable<any> {
    return this.apiService.post(Constants.API_CREATE_BY_GOOGLE, {
      access_token: token,
    }).pipe(
      map(({ data: responseData }) => {
        const credentials: Credentials = {
          user: responseData.applicationUser,
          accessToken: responseData.accessToken,
        };

        this.credentialsService.setCredentials(credentials, true);

        return responseData;
      }),
      catchError((error: HttpErrorResponse) => {
        this.apiService.handleError(error);

        return throwError(error);
      })
    );
  }

  signUpByFacebook(token: string): Observable<any> {
    return this.apiService.post(Constants.API_CREATE_BY_FACEBOOK, {
      access_token: token,
    }).pipe(
      map(({ data: responseData }) => {
        const credentials: Credentials = {
          user: responseData.applicationUser,
          accessToken: responseData.accessToken,
        };

        this.credentialsService.setCredentials(credentials, true);

        return responseData;
      }),
      catchError((error: HttpErrorResponse) => {
        this.apiService.handleError(error);

        return throwError(error);
      })
    );
  }

  recoverPassword(email: string): Observable<any> {
    return this.apiService
      .get(Constants.API_RECOVER_PASSWORD, {
        params: {
          email,
        },
      })
      .pipe(
        map(({ data: responseData }) => {
          return responseData;
        }),
        catchError((error: HttpErrorResponse) => {
          this.apiService.handleError(error);

          return throwError(error);
        })
      );
  }

  recoveryPassword(data: any): Observable<any> {
    return this.apiService.put(Constants.API_CHANGE_PASS, {
      password: data.password,
      confirmPassword: data.confirmPassword,
      tokenChangePassword: data.tokenChangePassword
    });
  }

  updatePassword(
    oldPassword: string,
    password: string,
    userId: string
  ): Observable<any> {
    return this.apiService.put(Constants.API_UPDATE_PASSWORD, {
      oldPassword,
      newPassword: password,
      applicationUserId: userId,
    });
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // TODO: Check the right API logout route and its parameters
    this.credentialsService.revokeCredentials();
    return of(true);
  }
}
