<nav *ngIf="showHeader">
  <div class="container header">
    <div class="logo-container" [class.centerHeader]="hideProfile">
      <img
        (click)="goHome()"
        src="assets/images/LogoGraneroExpress.png"
        alt="Granero"
      />
    </div>
    <!-- <div class="menus-container">
      <ul>
        <li>
          <a routerLink="/orders">
            Pedidos
          </a>
        </li>
      </ul>
    </div> -->
    <div class="account-container" *ngIf="!hideProfile">
      <ul>
        <li>
          <a title="Meus pedidos" [routerLink]="'/orders'" class='home-icon'>
            <ion-icon name="home-outline"></ion-icon>
          </a>
        </li>
        <li>
          <a title='Termos de uso' href='https://out.graneroexpress.com.br/terms/client.pdf' target='_blank' class='terms-icon'>
            <ion-icon name="document-outline"></ion-icon>
          </a>
        </li>
        <li>
          <div class="btn-menu">
            <div class="items-menu">
              <a
                routerLink="/profile"
                class="profile-pic"
                [ngStyle]="{ 'background-image': backgroundProfileImage }"
              ></a>
              <div class='logout-icon1'>
                <ion-icon
                  (click)="logOut()"
                  name="log-out-outline"
                ></ion-icon>
              </div>
            </div>
          </div>
          <!-- <ul class="dropdown-account">
            <li>
              <ion-icon class="icon" name="pencil-outline"></ion-icon
              ><a routerLink="/perfil">Editar Perfil</a>
            </li>
            <li>
              <ion-icon class="icon" name="log-out-outline"></ion-icon
              ><a routerLink="/logout">Sair</a>
            </li>
          </ul> -->
        </li>
      </ul>

      <div id="menuToggle">
        <input type="checkbox" />

        <span></span>
        <span></span>
        <span></span>

        <ul id="menu">
          <li>
            <a routerLink="/orders">Pedidos</a>
          </li>
          <li>
            <ion-icon class="icon" name="pencil-outline"></ion-icon
            ><a routerLink="/profile">Editar Perfil</a>
          </li>
          <li>
            <ion-icon class="icon" name="log-out-outline"></ion-icon
            ><a routerLink="/logout">Sair</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<main>
  <router-outlet></router-outlet>
</main>

<footer *ngIf="showHeader">
  <div class="container">
    <!-- <div class="links-container">
      <div class="sistem-information">
        <div class="title">
          Sistema Carreto
        </div>
        <div class="information">
          Versão 1.0
        </div>
      </div>
      <div class="help-links">
        <a class="information">
          Ajuda
        </a>
        <a class="information">
          Suporte
        </a>
      </div>
      <div class="suport-container">
        <a class="information">
          suporte@granero.com.br
        </a>
        <a class="information">
          (11) 1234-5678
        </a>
      </div>
      <div class="information-container">
        <div class="information">
          Granero Transportes © 2020.
        </div>
        <div class="information">
          Todos os direitos reservados.
        </div>
      </div>
    </div> -->
    <div class="single-link-footer">
      <p>
        @{{currentYear}} GRANERO EXPRESS
      </p>
    </div>
  </div>
</footer>
