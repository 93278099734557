import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard as AuthGuard } from './services/auth/authentication.guard';
import { APP_ROUTES } from './app.routes';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: APP_ROUTES.LOGIN,
  },
  {
    path: APP_ROUTES.LOGIN,
    loadChildren: () =>
      import('./screens/login/login.module').then((m) => m.LoginModule),
    data: {
      title: 'Login',
      hideHeader: true,
    },
  },
  {
    path: APP_ROUTES.CHANGE_PASSWORD,
    loadChildren: () =>
      import('./screens/change-passowrd/change-passowrd.module').then((m) => m.ChangePassowrdModule),
    data: {
      title: 'Alterar senha',
      hideHeader: true,
    },
  },
  {
    path: APP_ROUTES.REGISTER,
    loadChildren: () =>
      import('./screens/register/register.module').then(
        (m) => m.RegisterModule
      ),
    data: {
      title: 'Cadastro',
      hideHeader: true,
    },
  },
  {
    path: APP_ROUTES.ORDERS,
    loadChildren: () =>
      import('./screens/orders/orders.module').then((m) => m.OrdersModule),
    data: {
      title: 'Pedidos',
    },
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.NEW_ORDER,
    loadChildren: () =>
      import('./screens/new-order/new-order.module').then(
        (m) => m.NewOrderModule
      ),
    data: {
      title: 'Novo Pedido',
    },
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.PROFILE,
    loadChildren: () =>
      import('./screens/profile/profile.module').then((m) => m.ProfileModule),
    data: {
      title: 'Perfil',
    },
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.RATING,
    loadChildren: () =>
      import('./screens/rating/rating.module').then((m) => m.RatingModule),
    data: {
      title: 'Avaliar',
      hideProfile: true
    },
  },
  {
    path: '**',
    redirectTo: APP_ROUTES.ORDERS,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
